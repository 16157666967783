.loggedheader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  /* position: fixed; */
  width: 100vw;
  height: 40px;
  color: #000;
  padding: 8px 0;
}
.loggedheader img {
  height: 100%;
}
.loggedheader > .left {
  width: 70vw;
  padding-left: 8px;
  text-align: left;
}
.loggedheader > .right {
  width: 30vw;
  padding-right: 8px;
  text-align: right;
}

.header_bttn {
  padding: 10px;
  font-size: 12px;
  border: none;
  color: white;
  background-color: rgb(24, 29, 53);
}
