.bracket{

}
.quadrant{
    display: flex;
    overflow: scroll;
    padding: 0 16px;
}
.round{
    display: flex;
    flex-direction: column;
    margin: 16px;
    justify-content: space-around;
    width: 150px;
}
.matchup{
    display: flex;
    flex-direction: column;
}
.champion h4{
    margin: 0;
    margin-bottom: 16px;
}
.team{
    background-color: gray;
    color: black;
    min-width: 134px;
    padding: 0 8px;
}
.selected{
    background-color: white;
}
.champ{
    background-color: greenyellow;
}
/* Quadrant Buttons */
.qBtns{
    display: flex;
    width: 100vw;
}
.roundBtn{
    display: flex;
    flex: 1;
    justify-content: center;
    background-color: white;
    color: black;
}
.complete{
    background-color: green;
    color: white;
}