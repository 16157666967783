.reset {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reset__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #839cd6;
  padding: 16px;
}
.reset__textBox {
  padding: 8px;
  font-size: 16px;
  margin-bottom: 8px;
}
.reset__btn {
  padding: 8px;
  font-size: 16px;
  margin-bottom: 8px;
  border: none;
  color: white;
  background-color: rgb(24, 29, 53);
}
.reset div {
  margin-top: 8px;
}
