.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 50px;
  padding-top: 5vh;
  background-image: url("../assets/headerimg.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.header img {
  max-height: 100%;
  max-width: 100vw;
}
