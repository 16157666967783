body {
  background-color: rgb(24, 29, 53);
}
.login {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #839cd6;
  padding: 16px;
}
.login__textBox {
  padding: 8px 4px;
  font-size: 16px;
  margin-bottom: 8px;
}
.login__btn {
  padding: 8px;
  font-size: 16px;
  margin: 8px 0px;
  border: none;
  color: white;
  background-color: rgb(24, 29, 53);
}
.login div {
  margin-top: 8px;
}

a {
  color: rgb(24, 29, 53);
}
