.dashboard {
  min-height: 100vh;
  width: 100vw;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}
.spacer {
  padding-top: 48px;
}
.dashboard > button {
  font-size: 16px;
  font-weight: 600;
  padding: 4px 8px;
  background-color: rgb(131, 156, 214);
  border-color: rgb(131, 156, 214);
  color: white;
  margin: 8px 0px;
}
.dashboard__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}
.dashboard > h1 {
  margin-bottom: 4px;
  margin-top: 16px;
}
.dashboard > h5 {
  margin-bottom: 4px;
  margin-top: 0px;
}
.dashboard > .inner-card {
  margin: 8px 24px;
  margin-top: 0px;
  padding: 8px;
  background-color: white;
  color: black;
  border-radius: 4px;
  box-shadow: black;
}
table {
  width: 100%;
}
td {
  text-align: center;
}

.bracketContainer {
  overflow: scroll;
  width: 97vw;
  height: 97vh;
  background-color: #181c34;
  border-radius: 8px;
  padding: 8px;
}

.submitContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.submitContainer > button {
  background-color: seagreen;
  border: 0px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  font-weight: 600;
  border-radius: 4px;
  margin-left: 16px;
}
.submitContainer > button#cancel {
  background-color: red;
}
.upsetContainer{
  display: flex;
  width: 90vw;
  padding: 8px;
  max-width: 500px;
}
.upsetPick{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  align-items: center;
}
.upsetWinner{
  background-color: #109618;
}
.upsetLoser{
  background-color: #dc3912;
}
