.register {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #839cd6;
  padding: 16px;
}
.register__textBox {
  padding: 8px;
  font-size: 16px;
  margin-bottom: 8px;
}
.register__btn {
  padding: 8px;
  font-size: 16px;
  margin-bottom: 8px;
  border: none;
  color: white;
  background-color: rgb(24, 29, 53);
}
.register div {
  margin-top: 8px;
}
.register .input_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.register input {
  margin: 4px 4px;
}
