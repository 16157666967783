.footer-container {
  position: fixed;
  bottom: 0px;
  background-color: rgb(24, 29, 53);
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 40px;
  color: white;
  margin: 0px 8px;
  font-size: 12px;
}
.footer a {
  color: white;
}
.footer > .left {
  width: 33vw;
  padding-left: 8px;
  text-align: left;
}
.footer > .mid {
  width: 34vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.mid .social {
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.mid .social:hover {
  opacity: 0.5;
}
.mid .insta {
  background-image: url("https://i.ibb.co/HGKsWb1/instagram2x.png");
}
.mid .youtube {
  background-image: url("https://i.ibb.co/L9XdWr1/youtube2x.png");
}
.mid .facebook {
  background-image: url("https://i.ibb.co/L5V8DHb/facebook2x.png");
}
.footer > .right {
  width: 33vw;
  padding-right: 8px;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.footer > .right > div {
  margin-left: 8px;
}
