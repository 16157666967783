@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensanscondensed/v23/z7NHdQDnbTkabZAIOl9il_O6KJj73e7Fd_-7suD8S72Q.ttf) format('truetype');
}

@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensanscondensed/v23/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuvMQg.ttf) format('truetype');
}

@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensanscondensed/v23/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDuvMQg.ttf) format('truetype');
}

:root {
    --main-color: #A44333;
    --sec-color: #6ccff6;
  }

body {
    margin: 0;
    font-family: Open Sans;
    font-size: 18px;
    line-height: 1.44em;
    font-weight: 400;
    color: #000;
    background-color: #000;
    overflow-x: hidden;
    -webkit-font-smoothing:antialiased
}

p {
    color: #000;
    font-family: Open Sans;
    font-size: 16px;
    line-height: 22px;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    margin-top: 20px;
    margin-bottom: 20px;
}

h1, h2, h3, h4, h5, h6 {
    margin:0;
}

h1 {
    font-family: Open Sans Condensed;
    font-size: 60px;
    line-height: 70px;
}
h2 {
    color: #000000;
    font-family: Open Sans Condensed;
    font-size: 40px;
    line-height: 50px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
}

h3 {
    color: #000000;
    font-family: Open Sans Condensed;
    font-size: 34px;
    line-height: 45px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
}

h4 {
    font-family: Open Sans Condensed;
    font-size: 25px;
    line-height: 35px;
    font-weight: 700;
    text-transform: uppercase;
}

h5 {
    font-family: Open Sans Condensed;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
}

h6 {
    font-family: Open Sans Condensed;
    font-size: 14px;
    font-style: normal;
    line-height: 16px;
    margin-bottom: 4px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

a, p a {
    font-style: normal;
    font-weight: 400;
    color: var(--main-color);
    text-decoration: none;
}

a:hover, p a:hover {
    color: var(--sec-color);
    text-decoration: underline;
}

.centered-items{
    text-align: center;
}
.importanttext{
    color: var(--main-color);
}
/* BUTTON */
button{
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor:pointer;
}
button:hover{
    opacity: 0.6;
}

/* TABLE */
table{
    border-collapse: collapse;
}
thead{
    font-family: Open Sans Condensed;
    font-weight: 800;
}
.boldrow{
    font-family: Open Sans Condensed;
    font-weight: 800;
}
td, th{
border: 1px solid #ddd;
  padding: 4px;
}

/*FORMS*/
input[type=email], input[type=number], input[type=password], input[type=tel], input[type=text], input[type=url], textarea {
    position: relative;
    display: inline-block;
    vertical-align: top;
    -webkit-appearance: none;
    background-color: white;
    border: 1px solid #e8e8e9;
    border-radius: 0;
    color: #0d0d0d;
    cursor: pointer;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .025em;
    line-height: 14px;
    margin: 0 0 23px;
    outline: 0;
    padding: 8.5px 10px;
    text-transform: none;
    -webkit-transition: color .2s ease-out, background-color .2s ease-out, border-color .2s ease-out;
    -o-transition: color .2s ease-out, background-color .2s ease-out, border-color .2s ease-out;
    transition:color .2s ease-out, background-color .2s ease-out, border-color .2s ease-out
}

input[type=email]:focus, input[type=number]:focus, input[type=password]:focus, input[type=tel]:focus, input[type=text]:focus, input[type=url]:focus, textarea:focus {
    background-color: #f7f7f7;
    border-left: 4px solid;
    border-left-color: var(--main-color);
    padding-left:17px
}

/* Top Banner */
.topBanner{
    padding: 16px;
    background-color: red;
    border-bottom: 1px solid white;
    color: white;
    font-weight:900;
}


/* MEDIA */
@media only screen and (max-width: 680px) {
    h1 {
        font-size: 35px !important;
        line-height:45px !important
    }

    h2 {
        font-size: 27px !important;
        line-height:35px !important
    }

    h3 {
        font-size: 25px !important;
        line-height:35px !important
    }
}
@media only screen and (max-width: 1024px) {
    h1 {
        font-size: 45px !important;
        line-height:55px !important
    }

    h2 {
        font-size: 35px !important;
        line-height:45px !important
    }

    h3 {
        font-size: 30px !important;
        line-height:40px !important
    }
}