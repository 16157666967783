.curtain {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0px;
  align-self: center;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  padding-top: 16px;
}
.groupContainer {
  background-color: #181c34;
  border-radius: 8px;
  padding: 8px;
}
.groupView {
  color: white;
  text-align: center;
  max-height: 80vh;
  overflow: scroll;
}
.groupView h1 {
  margin: 4px;
}
.groupView table {
  border-collapse: collapse;
}
.groupView td {
  padding: 2px 8px;
}
.groupView tr:nth-child(odd) {
  background-color: #282f57;
}
.closebutton {
  margin-top: 16px;
  font-size: 16px;
  color: white;
  background-color: darkred;
  border-color: darkred;
}
