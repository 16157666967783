body {
  font-family: "Istok Web", sans-serif;
  background: url("http://picjumbo.com/wp-content/uploads/HNCK2189-1300x866.jpg")
    no-repeat #000;
  background-size: cover;
  min-height: 100%;
  margin: 0;
}
.bracketView {
  display: flex;
  justify-content: center;
  padding: 16px;
}

.bracketView > h1 {
  color: white;
  text-align: center;
}

.round {
  display: block;
  flex: 1;
  float: left;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 95%;
  width: 30.8333%\9;
}

.champion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.centerer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

li {
  font-size: 14px;
}

li:hover {
  opacity: 1;
  text-decoration-color: #fcfcfc;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.split {
  display: flex;
  flex:1;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}
.split-one {
  flex-wrap: wrap;
}
.inner-split{
  display: flex;
  flex: 1;
  flex-direction: row;
  /* flex-wrap: wrap; */
}
.champion i {
  color: #a0a6a8;
  font-size: 45px;
  padding: 10px 0;
}

.split-one .round {
  margin: 0 8px 0 0;
}

.split-two .round {
  margin: 0 0 0 8px;
}

.matchup {
  margin: 0;
  width: 100%;
  padding: 10px 0;
  height: 60px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.score {
  font-size: 11px;
  text-transform: uppercase;
  float: right;
  color: #2c7399;
  font-weight: bold;
  font-family: "Roboto Condensed", sans-serif;
  position: absolute;
  right: 5px;
}

.team {
  padding: 0 5px;
  margin: 3px 0;
  height: 25px;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.round-two .matchup {
  margin: 0;
  height: 60px;
  padding: 50px 0;
}

.round-three .matchup {
  margin: 0;
  height: 60px;
  padding: 130px 0;
}

.round-details {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 13px;
  color: rgb(131, 156, 214);
  text-transform: uppercase;
  text-align: center;
  height: 40px;
}

.round li:hover {
  opacity: 1;
}
.champion li:hover {
  opacity: 1;
}

.nohover li:hover {
  opacity: 0.45;
  cursor: default;
}

.round li.current {
  opacity: 1;
}

.round li.correct {
  opacity: 1;
  background-color: #5cca87;
}

.round li.incorrect {
  opacity: 1;
  background-color: #ca5c87;
}

.champion li.current {
  opacity: 1;
}

.champion li.correct {
  opacity: 1;
  background-color: #5cca87;
}

.champion li.incorrect {
  opacity: 1;
  background-color: #ca5c87;
}

.champion li,
.round li {
  background-color: #fff;
  box-shadow: none;
  opacity: 0.45;
  cursor: pointer;
}

.current li {
  opacity: 1;
}

.current li.team {
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  opacity: 1;
}

.vote-options {
  display: block;
  height: 52px;
}

.submit {
  margin: 0 auto;
  text-align: center;
  background-color: rgba(225, 225, 225, 0.9);
  padding: 10px;
}

.submitContainer {
  padding-bottom: 16px;
}

.submit-wrap {
  width: 200px;
  height: 30px;
  text-align: center;
  margin: auto;
  padding: 5px;
  border: 5px solid #fff;
  background-color: rgb(131, 156, 214);
}

.submit-wrap h3 {
  margin: 2px;
  color: #fcfcfc;
}

.final {
  margin: 0px 16px;
}

#options {
  color: #fcfcfc;
  margin: 20 auto;
}

#options-wrap {
  padding: 10px 0;
}

#details {
  margin: auto;
  width: 300px;
  text-align: center;
}

input {
  width: 220px;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid rgb(131, 156, 214);
}

#view-group {
  text-align: center;
  color: #fcfcfc;
}

td li {
  text-align: center;
  color: rgb(131, 156, 214);
}

td li:hover {
  text-align: center;
  color: #fcfcfc;
}

#leaderboard {
  text-align: center;
  color: #fff;
  margin: 0 20px;
}

#brackets {
  border-collapse: collapse;
  margin: 25px auto;
  font-size: 0.9em;
  font-family: sans-serif;
  max-width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

#brackets thead tr {
  background-color: rgb(131, 156, 214);
  color: #ffffff;
  text-align: left;
}

#brackets td,
th {
  padding: 6px 8px;
}

#brackets tbody tr {
  border-bottom: 1px solid #dddddd;
  background-color: #f3f3f3;
  font-weight: bold;
}

#brackets tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

#brackets tbody tr:hover {
  color: #009879;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
  }

  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.share-icon {
  color: #fff;
  opacity: 0.35;
}

.share-icon:hover {
  opacity: 1;
  -webkit-animation: pulse 0.5s;
  animation: pulse 0.5s;
}

.date {
  font-size: 10px;
  letter-spacing: 2px;
  font-family: "Istok Web", sans-serif;
  color: #7c537b;
}

@media screen and (min-width: 981px) and(max-width: 1099px) {
  .container {
    margin: 0 1%;
  }

  .split {
    width: 43%;
  }

  .split-one .vote-box {
    margin-left: 138px;
  }
}

@media screen and (max-width: 980px) {
  .bracketView {
    display: block;
  }
  .container {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
  }

  .split{
    width: 90%;
    margin: 35px 5%;
  }

  .split {
    border-bottom: 1px solid #b6b6b6;
    padding-bottom: 20px;
  }

  .hero p.intro {
    font-size: 24px;
  }

  .hero h1 {
    font-size: 3em;
    margin: 15px 0;
  }

  .hero p {
    font-size: 1em;
  }
}

@media screen and (max-width: 400px) {
  .split {
    width: 95%;
    margin: 25px 2.5%;
  }

  .round {
    width: 21%;
  }

  .current {
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    flex-grow: 1;
  }

  .hero h1 {
    font-size: 2.15em;
    letter-spacing: 0;
    margin: 0;
  }

  .hero p.intro {
    font-size: 1.15em;
    margin-bottom: -10px;
  }

  .round-details {
    font-size: 90%;
  }

  .hero-wrap {
    padding: 2.5em;
  }

  .hero p.year {
    margin: 5px 0 10px;
    font-size: 18px;
  }
}
